<template>
  <div :disabled="loading" class="text-centered">
    <field-errors v-if="showErrors" :errors="apiErrors.nonFieldErrors" />
    <template v-if="loading">
      <app-icon v-if="loading" name="refresh" class="rotating"></app-icon>&nbsp;
      <slot></slot>
    </template>
  </div>
</template>
<script>
import http from '@/http'

export default {
  props: {
    isLoading: {type: Boolean, default: false},
    showErrors: {type: Boolean, default: true},
    apiUrl: {type: String, default: null, required: false},
    apiParams: {default: () => {return {}}},
  },
  data () {
    return {
      loadingApi: false,
      apiErrors: {}
    }
  },
  async created () {
    await this.fetchData()
  },
  computed: {
    loading () {
      return this.loadingApi || this.isLoading
    }
  },
  methods: {
    async fetchData () {
      if (!this.apiUrl) {
        return
      }
      this.$emit("loading", true)
      this.loadingApi = true
      this.apiErrors = {}
      try {
        let response = await http.get(this.apiUrl, {params: this.apiParams})
        this.$emit("loaded", response.data)
      } catch (e) {
        this.$emit("error", e.backendErrors)
        this.apiErrors = e.backendErrors
      } finally {
        this.$emit("loading", false)
        this.loadingApi = false
      }
    }
  }
}
</script>
<template>
  <ul role="alert" v-if="finalErrors && finalErrors.length > 0" class="field-errors">
    <li v-for="error in finalErrors" :key="error">{{ error }}</li>
  </ul>
</template>
<script>
import get from 'lodash/get'

export default {
  props: {
    errors: {},
    field: {default: null},
  },
  computed: {
    finalErrors () {
      if (!this.errors) {
        return []
      }
      if (this.field) {
        let errors = get(this.errors, this.field)
        if (!errors) {
          return
        }
        if (Array.isArray(errors)) {
          return errors
        } else {
          return Object.values(errors)
        }
      }
      return this.errors
    }
  }
}
</script>
<template>
  <footer id="app-footer">
    <div class="padded container">
      © Alta Voce {{ year }} · 
      <router-link to="/get-started">
        <translate translate-context="*/*/*">About Alta Call TSM</translate>
      </router-link> ·
      <router-link to="/privacy">
        <translate translate-context="*/*/*">Privacy policy</translate>
      </router-link> · 
      <!-- DISABLED -->
      <a v-if="false" href="https://status.altacalltsm.fr">
        <translate translate-context="*/*/*">Status page</translate>
      </a>
    </div>
  </footer>
</template>
<script>
export default {
  computed: {
    year () {
      return (new Date()).getFullYear()
    }
  }
}
</script>
<template>
  <svg
   xmlns:dc="http://purl.org/dc/elements/1.1/"
   xmlns:cc="http://creativecommons.org/ns#"
   xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
   xmlns:svg="http://www.w3.org/2000/svg"
   xmlns="http://www.w3.org/2000/svg"
   id="logo"
   data-name="logo"
   viewBox="0 0 527 336"
   version="1.1"
   width="527"
   height="336">
  <title
     id="title6">Alta Call Logo</title>
  <rect
     class="cls-1"
     x="149.46423"
     y="6.5433807"
     width="28.41"
     height="256.84"
     rx="14.2"
     ry="14.2"
     id="rect8"
     style="fill:#f3f3f3" />
  <rect
     class="cls-1"
     x="98.734238"
     y="75.763397"
     width="25.700001"
     height="121.18"
     rx="12.85"
     ry="12.85"
     id="rect10"
     style="fill:#f3f3f3" />
  <rect
     class="cls-1"
     x="50.724239"
     y="144.97339"
     width="23"
     height="118.43"
     rx="11.5"
     ry="11.5"
     id="rect12"
     style="fill:#f3f3f3" />
  <rect
     class="cls-1"
     x="5.2642317"
     y="214.19339"
     width="20.290001"
     height="115.67"
     rx="10.15"
     ry="10.15"
     id="rect14"
     style="fill:#f3f3f3" />
  <rect
     class="cls-1"
     x="480.66418"
     y="6.5433807"
     width="40.580002"
     height="123.94"
     rx="20.290001"
     ry="20.290001"
     id="rect16"
     style="fill:#f3f3f3" />
  <rect
     class="cls-1"
     x="422.45422"
     y="75.763397"
     width="37.880001"
     height="121.18"
     rx="18.940001"
     ry="18.940001"
     id="rect18"
     style="fill:#f3f3f3" />
  <rect
     class="cls-1"
     x="366.9342"
     y="144.97339"
     width="35.169998"
     height="118.43"
     rx="17.59"
     ry="17.59"
     id="rect20"
     style="fill:#f3f3f3" />
  <rect
     class="cls-1"
     x="314.0842"
     y="214.03339"
     width="32.470001"
     height="115.82"
     rx="16.23"
     ry="16.23"
     id="rect22"
     style="fill:#f3f3f3" />
  <rect
     class="cls-1"
     x="202.98425"
     y="75.763397"
     width="29.76"
     height="121.18"
     rx="14.88"
     ry="14.88"
     id="rect24"
     style="fill:#f3f3f3" />
  <rect
     class="cls-1"
     x="257.91422"
     y="144.97339"
     width="31.110001"
     height="118.43"
     rx="15.56"
     ry="15.56"
     id="rect26"
     style="fill:#f3f3f3" />
  </svg>
</template>

import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "routes" */ './views/Home.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "routes" */ './views/Login.vue'),
    props: route => ({ next: route.query.next || '/', logout: route.query.logout || '' })
  },
  {
    path: '/password-reset',
    name: 'password-reset',
    component: () => import(/* webpackChunkName: "routes" */ './views/PasswordReset.vue'),
  },
  {
    path: '/account',
    component: () => import(/* webpackChunkName: "routes" */ './views/Account.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/onboarding',
    props: true,
    component: () => import(/* webpackChunkName: "onboarding" */ './views/Onboarding.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/dashboard',
    props: true,
    component: () => import(/* webpackChunkName: "dashboard" */ './views/dashboard/Base.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/dashboard/group/:group?',
    props: true,
    component: () => import(/* webpackChunkName: "dashboard" */ './views/dashboard/Base.vue'),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "",
        props: true,
        name: "dashboard",
        component: () => import(/* webpackChunkName: "dashboard" */ './views/dashboard/Home.vue'),
      },
      {
        path: "manage",
        props: true,
        name: "dashboard.group",
        component: () => import(/* webpackChunkName: "dashboard" */ './views/dashboard/GroupManage.vue'),
      },
      {
        path: "users",
        name: 'dashboard.users',
        props: true,
        component: () => import(/* webpackChunkName: "dashboard" */ './views/dashboard/Users.vue'),
      },
      {
        path: "labels",
        name: 'dashboard.labels',
        props: true,
        component: () => import(/* webpackChunkName: "dashboard" */ './views/dashboard/Labels.vue'),
      },
      {
        path: "users/:id",
        name: 'dashboard.users.detail',
        props: true,
        component: () => import(/* webpackChunkName: "dashboard" */ './views/dashboard/Users.vue'),
      },
      {
        path: "machines",
        name: "dashboard.machines",
        props: true,
        component: () => import(/* webpackChunkName: "dashboard" */ './views/dashboard/Machines.vue'),
      },
      {
        path: "machines/:id",
        name: "dashboard.machines.detail",
        props: true,
        component: () => import(/* webpackChunkName: "dashboard" */ './views/dashboard/Machines.vue'),
      },
      {
        path: "issues",
        name: "dashboard.issues",
        props: true,
        component: () => import(/* webpackChunkName: "dashboard" */ './views/dashboard/Issues.vue'),
      },
      {
        path: "outdated-machines",
        name: "dashboard.outdated-machines",
        props: true,
        component: () => import(/* webpackChunkName: "dashboard" */ './views/dashboard/OutdatedMachines.vue'),
      },
      {
        path: "abtests",
        name: "dashboard.abtests",
        props: true,
        component: () => import(/* webpackChunkName: "dashboard" */ './views/dashboard/Abtests.vue'),
      },
      {
        path: "abtests/request",
        name: "dashboard.requests",
        props: true,
        component: () => import(/* webpackChunkName: "dashboard" */ './views/dashboard/AbtestsRequest.vue'),
      },
      {
        path: "abtests/:id/edit",
        name: "dashboard.abtests.edit",
        props: true,
        component: () => import(/* webpackChunkName: "dashboard" */ './views/dashboard/AbtestsRequest.vue'),
      },
    ]
  },
  {
    path: '/help',
    component: () => import(/* webpackChunkName: "docs" */ './views/help/Base.vue'),
    meta: {
      requiresAuth: false,
    },
    children: [
      {
        path: "",
        component: () => import(/* webpackChunkName: "docs" */ './views/help/Home.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "docs",
        component: () => import(/* webpackChunkName: "docs" */ './views/help/Docs.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "docs/web",
        component: () => import(/* webpackChunkName: "docs" */ './views/help/DocsWeb.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "faq",
        component: () => import(/* webpackChunkName: "docs" */ './views/help/Faq.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "setup",
        component: () => import(/* webpackChunkName: "docs" */ './views/help/Setup.vue'),
        meta: {
          requiresAuth: false,
        },
      },
      // DISABLED
      // {
      //   path: "software-overview",
      //   component: () => import(/* webpackChunkName: "docs" */ './views/help/SoftwareOverview.vue'),
      //   meta: {
      //     requiresAuth: false,
      //   },
      // },
      {
        path: "api",
        component: () => import(/* webpackChunkName: "api" */ './views/help/Api.vue'),
        meta: {
          requiresAuth: false,
          hideFooter: true,
        },
      },
    ]
  },
  {
    path: '/organization',
    component: () => import(/* webpackChunkName: "organization" */ './views/organization/Base.vue'),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "",
        redirect: '/organization/accounts',
      },
      {
        path: "settings",
        component: () => import(/* webpackChunkName: "organization" */ './views/organization/Settings.vue'),
      },
      {
        path: "accounts",
        component: () => import(/* webpackChunkName: "organization" */ './views/organization/Accounts.vue'),
      },
      {
        path: "groups",
        component: () => import(/* webpackChunkName: "organization" */ './views/organization/Groups.vue'),
      },
    ]
  },
  {
    path: "/downloads",
    component: () => import(/* webpackChunkName: "downloads" */ './views/Downloads.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/privacy",
    component: () => import(/* webpackChunkName: "privacy" */ './views/PrivacyPolicy.vue'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/get-started",
    component: () => import(/* webpackChunkName: "get-started" */ './views/GetStarted.vue'),
    meta: {
      requiresAuth: false,
    },
  },
  // DISABLED
  // {
  //   path: "/feedback",
  //   component: () => import(/* webpackChunkName: "feedback" */ './views/Feedback.vue'),
  //   meta: {
  //     requiresAuth: true,
  //   },
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash
      }
    }
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  },
  
})

export default router

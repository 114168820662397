<template>
  <app-menu class="no-print"></app-menu>
  <div class="content">
    <main>
      <router-view v-slot="{ Component }">
        <transition name="fade" mode="out-in">
          <component :is="Component" />
        </transition>
      </router-view>
    </main>
    <app-footer v-if="!$route.meta.hideFooter"></app-footer>
    <impersonate-widget />
  </div>
</template>
<script>
import AppFooter from '@/components/AppFooter'
import AppMenu from '@/components/AppMenu'

import { defineAsyncComponent } from 'vue'
import * as Sentry from "@sentry/browser";

import locales from './language'
import http from '@/http'

export default {
  components: {
    AppFooter,
    AppMenu,
    ImpersonateWidget: defineAsyncComponent(() => import(/* webpackChunkName: "impersonate" */ "@/components/ImpersonateWidget")),
  },
  async created () {
    await this.$store.dispatch("fetchGroups", http)
    await this.$store.dispatch("fetchOrganization", http)
    this.$store.commit("selectBestGroup", this.$route.params.group || this.$store.state.selectedGroupId)
    if (this.$route.fullPath.startsWith("/help/api#?route=")) {
      console.log("Redirecting to /help/api to avoid broken route")
      this.$router.push("/help/api")
    }
  },
  mounted () {
    document.addEventListener('click', (event) => {
      // small tweak so that internal links included in translated text
      // are still handled by vue router and don't force a page reload
      if (event.target.tagName === 'A' && event.target.classList.contains('internal')) {
        event.preventDefault()
        this.$router.push(event.target.getAttribute('href'))
      }
    })
  },
  methods: {
    autodetectLanguage () {
      const userLanguage = navigator.language || navigator.userLanguage
      console.log("Autodetecting language from", userLanguage)
      const available = locales.locales.map(e => { return e.code })
      let candidate
      const matching = available.filter((a) => {
        return userLanguage.replace('-', '_') === a
      })
      const almostMatching = available.filter((a) => {
        return userLanguage.replace('-', '_').split('_')[0] === a.split('_')[0]
      })
      if (matching.length > 0) {
        candidate = matching[0]
      } else if (almostMatching.length > 0) {
        candidate = almostMatching[0]
      } else {
        return
      }
      this.$store.commit('currentLanguage', candidate)
    },
  },
  watch: {
    "$store.state.user": {
      handler (v) {
        Sentry.setContext("user", v || v)
      },
      immediate: true
    },
    '$store.state.currentLanguage': {
      immediate: true,
      handler (v) {
        if (!v) {
          return this.autodetectLanguage()
        }
        const htmlLocale = v.toLowerCase().replace('_', '-')
        document.documentElement.setAttribute('lang', htmlLocale)
        this.$language.current = v
      }
    },
  }
}
</script>
<style lang="scss">
@import "style/_main";
</style>
